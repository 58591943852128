import React from "react"
import {
    Show,
    SimpleShowLayout,
    TextField,
    DateField,
    NumberField,
    BooleanField
} from "react-admin"

export default props => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="id" />
            {/* <TextField source="UUID" /> */}
            <TextField source="PNRNumber" label="PNR Number" />
            <DateField source="BookingDate" label="Booking Date" />
            <DateField source="CheckIn" label="Check-In" />
            <DateField source="CheckOut" label="Check-Out" />
            <NumberField source="Night" />
            <NumberField source="Room" />
            <NumberField source="Quantity" />
            <TextField source="CustomerCode" label="Customer Code" />
            <TextField source="CustomerName" label="Customer Name" />
            <TextField source="UserID" label="User ID" />
            <BooleanField source="Processed" />
            <BooleanField source="HasUser" />
            <BooleanField source="HasPoints" />
            <TextField source="AgentCurrency" label="Customer Currency" />
            <TextField source="CustomerCountry" label="Customer Country" />
            <TextField source="CustomerCity" label="Customer City" />
            <TextField source="SellCurr" label="Selling Currency" />
            <NumberField source="AgentSelling" label="Agent Selling" />
            <NumberField source="TTV" label="TTV" />
            {/* <NumberField source="GroupLineNo" label="Group Line No." /> */}
            <TextField source="HotelId" label="Hotel ID" />
            <TextField source="HotelName" label="Hotel" />
            <TextField source="Country" label="Hotel Country" />
            <TextField source="City" label="Hotel City"/>
            {/* <TextField source="PNR" label="PNR" /> */}
            {/* <TextField source="PONO" label="PONO" /> */}
            <TextField source="PaxName" label="Pax Name" />
            {/* <TextField source="PsCustomerCode" label="PS Customer Code" /> */}
            {/* <TextField source="PsHotelId" label="PS Hotel ID" /> */}
            {/* <TextField source="PsSupplierId" label="PS Supplier ID" /> */}
            {/* <TextField source="SOREF" label="SOREF" /> */}
            <TextField source="CurrSalesBiling" label="Cost Currency" />
            <NumberField source="SalesBase" label="Sales Base" />
            <TextField source="JarvisSupplierId" label="Jarvis Supplier ID" />
            <TextField source="JarvisSupplierName" label="Jarvis Supplier Name" />
            <DateField source="InvoiceDate" label="Invoice Date" />
            <TextField source="InvoiceNumber" label="Invoice Number" />
            <TextField source="VoucherNumber" label="Voucher Number" />
            <TextField source="WebInvoiceNumber" label="Web Invoice Number" />
            <DateField source="SatlementDate" label="Settlement Date" />
            {/* <DateField source="UTCCreated" />
            <TextField source="CreatedBy" />
            <DateField source="UTCModified" />
            <TextField source="ModifiedBy" /> */}
        </SimpleShowLayout>
    </Show>
);