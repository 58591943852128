import React from "react";
import {
    Datagrid,
    DateField,
    List,
    NumberField,
    Pagination,
    TextField,
    Button,
    ShowButton,
    showNotification,
    refreshView,
    fetchStart,
    fetchEnd
} from "react-admin";
import Filter from "./Filter.js";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { connect } from 'react-redux';

const CheckMemberButton = connect(null, {
    refreshView,
    showNotification,
})(props => (
    <Button label='Check Member' onClick={() => {
        const {
            record,
            refreshView,
            showNotification,
        } = props;
        fetchStart();
        fetch(`${process.env.REACT_APP_API_URL}/powersuite/members`, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=utf-8',
                'Authorization': `Bearer ${localStorage.token}`,
                'X-API-KEY': process.env.REACT_APP_API_KEY,
            },
            method: 'POST',
            body: JSON.stringify({
                id: record.id,
            }),
        })
            .then(response => response.json())
            .then(response => {
                showNotification(response.message || 'Member checked', response.error ? 'warning' : 'info');
                refreshView();
                fetchEnd();
            })
            .catch(error => {
                console.error(error);
                fetchEnd();
            });
    }}>
        <CheckCircleIcon />
    </Button>
));

const CheckSupplierButton = connect(null, {
    refreshView,
    showNotification,
})(props => (
    <Button label='Check Supplier' onClick={() => {
        const {
            record,
            refreshView,
            showNotification,
        } = props;
        fetchStart();
        fetch(`${process.env.REACT_APP_API_URL}/powersuite/supplier`, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=utf-8',
                'Authorization': `Bearer ${localStorage.token}`,
                'X-API-KEY': process.env.REACT_APP_API_KEY,
            },
            method: 'POST',
            body: JSON.stringify({
                id: record.id,
            }),
        })
            .then(response => response.json())
            .then(response => {
                showNotification(response.message || 'Supplier checked', response.error ? 'warning' : 'info');
                refreshView();
                fetchEnd();
            })
            .catch(error => {
                console.error(error);
                fetchEnd();
            });
    }}>
        <CheckCircleIcon />
    </Button>
));

const DistributePointsButton = connect(null, {
    refreshView,
    showNotification,
})(props => (
    <Button label="Distribute Pts" onClick={() => {
        const {
            record,
            refreshView,
            showNotification,
        } = props;
        fetchStart();
        fetch(`${process.env.REACT_APP_API_URL}/powersuite/points`, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=utf-8',
                'Authorization': `Bearer ${localStorage.token}`,
                'X-API-KEY': process.env.REACT_APP_API_KEY,
            },
            method: 'POST',
            body: JSON.stringify({
                id: record.id,
            }),
        })
            .then(response => response.json())
            .then(response => {
                showNotification(response.message || 'Points distributed', response.error ? 'warning' : 'info');
                refreshView();
                fetchEnd();
            })
            .catch(error => {
                console.error(error);
                fetchEnd();
            });
    }}>
        <CheckCircleIcon />
    </Button>
));

const AddtionalButton = props => {
    const {
        record
    } = props;
    if (!record.Processed && !record.HasPoints) {
        if (!record.HasUser) return (<CheckMemberButton {...props} />)
        else if (!record.HasSupplier) return (<CheckSupplierButton {...props} />) 
        else return (<DistributePointsButton {...props} />)
    }
    return (<div />);
};

export default props => (
    <List
        {...props}
        // exporter={false}
        filters={<Filter />}
        bulkActionButtons={false}
        pagination={<Pagination rowsPerPageOptions={[25, 50, 100, 200]} />}
        perPage={25}
        sort={{
            field: "BookingDate",
            order: "DESC"
        }}
    >
        <Datagrid>
            <TextField
                source="PNRNumber"
                label="PNR Number"
                sortable={false}
            />
            <TextField
                source="JarvisSupplierName"
                label="Supplier Name"
                sortable={false}
            />
            {/* <TextField
                source="VoucherNumber"
                label="Voucher Number"
                sortable={false}
            /> */}
            <DateField
                source="BookingDate"
                label="Booking Date"
                sortable={false}
            />
            <TextField
                source="HotelName"
                label="Hotel"
                sortable={false}
            />
            <DateField
                source="CheckIn"
                label="Check-In"
                sortable={false}
            />
            <DateField
                source="CheckOut"
                label="Check-Out"
                sortable={false}
            />
            <NumberField source="Room" sortable={false} />
            <NumberField source="Night" sortable={false} />
            <NumberField
                source="Quantity"
                label="RN"
                sortable={false}
            />
            <TextField
                source="SellCurr"
                label="SellCurr"
                sortable={false}
            />
            <NumberField source="TTV" label="TTV" sortable={false} />
            {/* <TextField
                source="PaxName"
                label="Pax Name"
                sortable={false}
            /> */}
            <TextField
                source="CustomerCode"
                label="Agent Code"
                sortable={false}
            />
             <TextField
                source="AgentCurrency"
                label="Agent Currency"
                sortable={false}
            />
            <TextField
                source="UserID"
                label="User ID"
                sortable={false}
            />
            <ShowButton />
            <AddtionalButton />
        </Datagrid>
    </List>
);
