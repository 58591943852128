import React from "react";
import { BooleanField, Datagrid, EmailField, List, Pagination, Responsive, SimpleList, TextField } from "react-admin";
import Filter from "./Filter";
import BulkActionButtons from "./BulkActionButtons";
import Actions from "./Actions";

export default props => (
  <List
    {...props}
    sort={{ field: "AgentCode", order: "ASC" }}
    actions={<Actions permissions = {props.permissions} />}
    exporter={false}
    filters={<Filter />}
    bulkActionButtons={<BulkActionButtons />}
    pagination={<Pagination rowsPerPageOptions={[25, 50, 100, 200]} />}
    perPage={25}

  >
    <Responsive
      small={
        <SimpleList
          primaryText={({Name}) => Name}
          secondaryText={({AgentCode}) => AgentCode}
          tertiaryText={({Country}) => Country}
          linkType="show"
        />
      }
      medium={
        <Datagrid rowClick="show">
          <TextField source="id" label="Agent Code" sortable={false} />
          <TextField source="LegacyAgentCode" label="Legacy Agent Code" sortable={false} />
          <TextField source="Name" label="Name" sortable={false} />
          {/* <TextField source="Phone" label="Phone Number" sortable={false} /> */}
          <EmailField source="Email" label="Agent Email" sortable={false} />
          <TextField source="City" sortable={false} />
          <TextField source="Country" sortable={false} />
          <TextField source="Currency" sortable={false} />
          <BooleanField source="IsXML" label="XML Agent" sortable={false} />
          <BooleanField source="IsPanorama" label="Panorama Agent" sortable={false} />
          <BooleanField source="IsBlacklisted" label="Blacklist" sortable={false} />
          <BooleanField source="IsAffiliate" label="Affiliate" sortable={false} />
        </Datagrid>
      }
    />
  </List>
);
