import React from "react";
import {
  Show,
  Pagination,
  TextField,
  TabbedShowLayout,
  Tab,
  Datagrid,
  ReferenceManyField,
  EmailField,
  BooleanField,
  ChipField
} from "react-admin";

export default props => (
  <Show {...props}>
    <TabbedShowLayout>
      <Tab label="Summary">
        <TextField
          source="AgentCode"
          label="Agent Code"
          style={{ fontSize: 12 }}
        />
        <TextField source="Name" label="Name" />
        <TextField source="Phone" label="Phone Number" />
        <EmailField source="Email" label="Agent Email" />
        <TextField source="Country" label="Country" />
        <TextField source="Currency" label="Currency" />
        <BooleanField source="IsXML" label="XML Agent" />
        <BooleanField source="IsPanorama" label="Panorama Agent" />
        <BooleanField source="IsBlacklisted" label="Blacklist Redemption" />
        <BooleanField source="IsAffiliate" label="Affiliate" />
      </Tab>
      <Tab label="Member" path="members">
        <ReferenceManyField
          label="Members"
          reference="members"
          target="AgentCode"
          source="AgentCode"
          pagination={<Pagination />}
        >
          <Datagrid>
            <TextField source="UserId" label="User ID" sortable={false} />
            <TextField source="OpsigoUserId" label="Opsigo User Id" sortable={false} />
            <TextField source="AgentCode" label="Agent Code" sortable={false} />
            <TextField source="Name" label="User Name" sortable={false} />
            <TextField source="agent.Name" label="Agent Name" sortable={false} />
            <TextField source="agent.Country" label="Country" sortable={false} />
            <TextField source="agent.City" label="Area" sortable={false} />
            <EmailField source="Email" label="User Email" sortable={false} />
            <TextField source="Phone" label="Phone" sortable={false} />
            <ChipField source="Status" label="Status" sortable={false} />
            <BooleanField source="IsMultiUser" label="Multiuser" sortable={false} />
          </Datagrid>
        </ReferenceManyField>
      </Tab>
    </TabbedShowLayout>
  </Show>
);
