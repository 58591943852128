import React from "react";
import { BooleanField, Datagrid, FunctionField, DateField, List, NumberField, Pagination, TextField } from "react-admin";
import PromotionFilter from "./Filter";
// import { exporter } from "./Exporter";
import Action from './Action';
import { formatNumber } from '../../helpers/number-helper';

export default props => (
  <List
    {...props}
    actions = {<Action />}
    exporter={false}
    // bulkActionButtons={false}
    filters={<PromotionFilter />}
    pagination={<Pagination rowsPerPageOptions={[25, 50, 100, 200]} />}
    perPage={25}
  >
    <Datagrid rowClick="show">
      <TextField source="Category" sortable={false} />
      <FunctionField label="Reference" sortable={false} render={
        ({Ref, hotel}) => hotel && hotel.Name ? hotel.Name : Ref
      } />
      <DateField source="BookingFrom" label="Booking From" sortable={false} />
      <DateField source="BookingTo" label="Booking To" sortable={false} />
      <DateField source="StayFrom" label="Stay From" sortable={false} />
      <DateField source="StayTo" label="Stay To" sortable={false} />
      <NumberField source="Points" sortable={false} />
      {/* Old code
      <NumberField source="GoldLimit" sortable={false} />
      <NumberField source="GoldBalance" sortable={false} />
      <NumberField source="SilverLimit" sortable={false} />
      <NumberField source="SilverBalance" sortable={false} /> 
      end old code*/}
      <FunctionField source="Limit" label="Limit" render={record => formatNumber(record.Limit)} sortable={false} />
      <FunctionField source="Balance" label="Balance" render={record => formatNumber(record.Balance)} sortable={false} />
      <TextField source="Currency" label="Currency" sortable={false} />
      <TextField source="ApplicationCategory" label="Application Category" sortable={false} />
      <TextField source="AppliesTo" label="Applies To" sortable={false} />
      <BooleanField source="Active" sortable={false} />
      <BooleanField source="Publish" sortable={false} />
    </Datagrid>
  </List>
);
