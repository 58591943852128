import React from "react";
import {
    ChipField,
    Datagrid,
    DateField,
    EmailField,
    List,
    Pagination,
    ShowButton,
    TextField,
    BooleanField,
    FunctionField
} from "react-admin";
import AddPointsButton from "./AddPointsButton";
import TransferPointsButton from "./TransferPointsButton";
import ResetPasswordButton from "./ResetPasswordButton";
import Filter from "./Filter";
import Actions from './Action'
// import { exporter } from "./MemberExport";
import { formatNumber } from '../../helpers/number-helper';

const _ = require("lodash");

export default ({ permissions, ...props }) => (
    
    <List
        {...props}
        filters={<Filter />}
        actions={<Actions permissions = {props.permissions} />}
        exporter={false} 
        // bulkActionButtons={false}
        pagination={<Pagination rowsPerPageOptions={[25, 50, 100, 200]} />}
        perPage={25}
        sort={{
            field: "UTCCreated",
            order: "DESC"
        }}
    >
        <Datagrid>
            <TextField source="AgentCode" label="Agent Code" sortable={false} />
            <TextField source="agent.Name" label="Agent Name" sortable={false}/>
            <TextField source="agent.Country" label="Agent Country" sortable={false}/>
            <TextField source="TierName" label="Tier Name" sortable={false} style={{ textTransform: 'uppercase' }} />
            <TextField source="UTCTierExpired" label="Tier Expired" sortable={false} />
            <TextField source="UserId" label="User ID" sortable={false} />
            <TextField source="LegacyAgentCode" label="Legacy Agent Code" sortable={false} />
            <TextField source="Name" label="User Name" sortable={false} />
            {/* <NumberField source="Point" label="Points" sortable={false} /> */}
            <FunctionField source="Point" label="Point" render={record => formatNumber(record.Point)} sortable={false} style={{ textAlign: 'right' }} />
            <BooleanField source="IsBlacklisted" label="Blacklist Redemption" sortable={false} />
            <EmailField source="Email" sortable={false} />
            <TextField source="Phone" sortable={false} />
            <TextField source="Area" sortable={false} />
            <ChipField source="Status" sortable={false} />
            <DateField
                source="UTCCreated"
                label="Registration Date"
                showTime
                sortable={false}
            />
            <ShowButton />
            {permissions &&
                _.intersection(permissions, [
                    "ALL",
                    "SUPERADMIN",
                    "SUPERVISOR",
                    "POINTS",
                    "ADD_POINTS"
                ]).length > 0 && <AddPointsButton />}
            {permissions &&
                _.intersection(permissions, [
                    "ALL",
                    "SUPERADMIN",
                    "SUPERVISOR",
                    "POINTS",
                    "TRANSFER_POINTS"
                ]).length > 0 && <TransferPointsButton />}
            {permissions &&
                _.intersection(permissions, ["ALL", "SUPERADMIN", "ADMIN"])
                    .length > 0 && <ResetPasswordButton />}
        </Datagrid>
    </List>
);
